const contact = [


    {
        telefono: 543815872696,
        email: 'invertirsimplesas@gmail.com',
        direccion: 'Tucuman',
        instagram: 'https://www.instagram.com/',
        facebook: 'https://www.facebook.com/'
    }

]
export default contact