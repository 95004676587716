const url = 'https://invertirsimple.ar/'
const ruta = 'videos'
const videos = [


    {
        id: 1,
        titulo: 'Video 1',
        descripcion: '  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa sit at aliquam fugit ratione perferendis quo corporis quisquam, laboriosam reprehenderit quaerat ab, ducimus sint, omnis incidunt temporibus eligendi fuga officia?',
        video: `${url}/${ruta}/video.mp4`
    },
    {
        id: 2,
        titulo: 'Video 2',
        descripcion: '  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa sit at aliquam fugit ratione perferendis quo corporis quisquam, laboriosam reprehenderit quaerat ab, ducimus sint, omnis incidunt temporibus eligendi fuga officia?',
        video: `${url}/${ruta}/video2.mp4`
    },
    {
        id: 3,
        titulo: 'Video 3',
        descripcion: '  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa sit at aliquam fugit ratione perferendis quo corporis quisquam, laboriosam reprehenderit quaerat ab, ducimus sint, omnis incidunt temporibus eligendi fuga officia?',
        video: `${url}/${ruta}/video3.mp4`
    },
    {
        id: 2,
        titulo: 'Video 2',
        descripcion: '  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa sit at aliquam fugit ratione perferendis quo corporis quisquam, laboriosam reprehenderit quaerat ab, ducimus sint, omnis incidunt temporibus eligendi fuga officia?',
        video: `${url}/${ruta}/video2.mp4`
    },
    {
        id: 3,
        titulo: 'Video 3',
        descripcion: '  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa sit at aliquam fugit ratione perferendis quo corporis quisquam, laboriosam reprehenderit quaerat ab, ducimus sint, omnis incidunt temporibus eligendi fuga officia?',
        video: `${url}/${ruta}/video3.mp4`
    },
    {
        id: 1,
        titulo: 'Video 1',
        descripcion: '  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa sit at aliquam fugit ratione perferendis quo corporis quisquam, laboriosam reprehenderit quaerat ab, ducimus sint, omnis incidunt temporibus eligendi fuga officia?',
        video: `${url}/${ruta}/video.mp4`
    },
    {
        id: 1,
        titulo: 'Video 1',
        descripcion: '  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa sit at aliquam fugit ratione perferendis quo corporis quisquam, laboriosam reprehenderit quaerat ab, ducimus sint, omnis incidunt temporibus eligendi fuga officia?',
        video: `${url}/${ruta}/video.mp4`
    },
    {
        id: 2,
        titulo: 'Video 2',
        descripcion: '  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa sit at aliquam fugit ratione perferendis quo corporis quisquam, laboriosam reprehenderit quaerat ab, ducimus sint, omnis incidunt temporibus eligendi fuga officia?',
        video: `${url}/${ruta}/video2.mp4`
    },
    {
        id: 3,
        titulo: 'Video 3',
        descripcion: '  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa sit at aliquam fugit ratione perferendis quo corporis quisquam, laboriosam reprehenderit quaerat ab, ducimus sint, omnis incidunt temporibus eligendi fuga officia?',
        video: `${url}/${ruta}/video3.mp4`
    },
    {
        id: 2,
        titulo: 'Video 2',
        descripcion: '  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa sit at aliquam fugit ratione perferendis quo corporis quisquam, laboriosam reprehenderit quaerat ab, ducimus sint, omnis incidunt temporibus eligendi fuga officia?',
        video: `${url}/${ruta}/video2.mp4`
    },
    {
        id: 3,
        titulo: 'Video 3',
        descripcion: '  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa sit at aliquam fugit ratione perferendis quo corporis quisquam, laboriosam reprehenderit quaerat ab, ducimus sint, omnis incidunt temporibus eligendi fuga officia?',
        video: `${url}/${ruta}/video3.mp4`
    },
    {
        id: 1,
        titulo: 'Video 1',
        descripcion: '  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa sit at aliquam fugit ratione perferendis quo corporis quisquam, laboriosam reprehenderit quaerat ab, ducimus sint, omnis incidunt temporibus eligendi fuga officia?',
        video: `${url}/${ruta}/video.mp4`
    },



]
export default videos